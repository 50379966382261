import React, {useState, FormEvent} from 'react'
import {Link, navigate} from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import {useAuth} from '../util/use-auth'

const Signup: React.FC = () => {
  const [message, setMessage] = useState(null as string | null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const auth = useAuth()

  const validateForm = (): boolean => {
    return email.length > 0 && password.length > 0 && password === confirmPassword
  }

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()
    if (!auth) return
    setMessage(null)
    setIsLoading(true)
    try {
      await auth.signUp(email, password)
      // Go to the confirmation page to handle the confirmation
      navigate(`/confirm/?email=${encodeURIComponent(email)}`)
    } catch (error) {
      setMessage((error as any).message)
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <Layout>
      <Head title="Signup" />
      <div>
        <form onSubmit={handleSubmit}>
          <label>
            Email
            <br />
            <input
              type="email"
              value={email}
              onChange={(e): void => {
                setMessage(null)
                setEmail(e.target.value)
              }}
            />
          </label>
          <label>
            Password
            <br />
            <input
              type="password"
              value={password}
              onChange={(e): void => {
                setMessage(null)
                setPassword(e.target.value)
              }}
            />
          </label>
          <label>
            Confirm Password
            <br />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e): void => {
                setMessage(null)
                setConfirmPassword(e.target.value)
              }}
            />
          </label>
          <div className="error">{message}</div>
          <div className="pt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-full"
              disabled={!validateForm() || isLoading}>
              {isLoading ? 'Submitting...' : 'Signup'}
            </button>
          </div>
        </form>
      </div>

      <br />

      <div>
        Already have an account? <Link to="/login/">Login</Link>.
      </div>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default Signup
